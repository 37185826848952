.home-section {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.home-title {
  font-weight: 500;
  text-shadow: 0 1px #05377048;
  
  span {
    color: $primary-color;
  }
}

.home-rotating-text {
  font-weight: 300;
}

.home-buttons {
  display: flex;
  justify-content: center;
}

.home-image {
  padding: 2rem;
  max-width: 25rem;

  @media (max-width: 576px) { 
    max-width: 20rem;
  }
}
.experience-card {
  display: flex;
  margin: 1rem;

  div {
    margin: 1rem;
  }

  @media (max-width: 576px) { 
    flex-direction: column;
  }
}

.experience-date {
  float: left;
  color: $primary-color;
}

.experience-company {
  font-size: 1.2rem;
  font-weight: bold;
}

.experience-role {
}
$duration: .2s;
$distance: 8px;
$easeOutBack: cubic-bezier(0.175, 0.885, 0.320, 1.275);

.nav-link {
  position: relative;
  font-weight: 500;
  text-decoration: none;

   &:before,
  &:after {
    content: '';
    position: absolute;
    bottom: 2px;
    left: 0; right: 0;
    height: 3px;
    background-color: $primary-color;
  }
  &:before {
    opacity: 0;
    transform: translateY(- $distance);
    transition: transform 0s $easeOutBack, opacity 0s;
  }
  &:after {
    opacity: 0;
    transform: translateY($distance/2);
    transition: transform $duration $easeOutBack, opacity $duration;
  }
  &:hover,
  &:focus {
    &:before,
    &:after {
      opacity: 1;
      transform: translateY(0);
    }
    &:before {
      transition: transform $duration $easeOutBack, opacity $duration;
    }
    &:after {
      transition: transform 0s $duration $easeOutBack, opacity 0s $duration;
    }
  }
}

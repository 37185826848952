.a-blue {
  color: $primary-color;
  text-decoration: none;

  &:hover {
    color: $primary-color;
    text-decoration: underline;
  }
}

.btn-icon {
  background-color: transparent;
  border: none;

  &:hover {
    color: $primary-color !important;
  }
}

.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem;
  padding-top: 4rem;
  padding-bottom: 2rem;
}

.section-title {
  font-weight: 600;
  border-bottom: 4px solid $primary-color;
}
